<template>
  <div>
    <div v-if="isloaded" class="dali-style-box">
      <div class="page-wrapper">
        <!-- <div class="toptips" style="display:none">
          <i class="iconfont icon-icon_warn"></i>
          <div>数据授权时，请勿使用该公司账号登录电子税务局，或者在其他平台进行数据授权，否则本次数据采集将被中断。</div>
        </div> -->
        <div class="section-box">
          <report-method api-prefix="daqc" @setName="changeName" @skip="handleSkip" class="report-method"
            v-if="!isTaxAutoProcessing" ref="showReportRef" :thirdDataSource="thirdDataSource"
            :validateMethod="validateMethod" :zq="zq" :uuid="uuid" :company-info="companyInfo">

          </report-method>
        </div>
      </div>

      <div class="footer2" v-if="!isTaxAutoProcessing">
        <button @click="handleBtnNext" class="btn btn-primary btn-next"
          v-if="$route.query.from != 'jinshuian' && $route.query.from != 'lvat'">提交</button>
        <button class="btn btn-share btn-next"
          v-if="$route.query.from == 'jinshuian' && $route.name != 'UploadTable-oem-v3'" style="display:none"
          @click="shareCodeDialog"> <i class="iconfont icon-icon_share" style="margin-right:4px;font-size:14px;"></i>
          邀请他人填写</button>
        <!-- <button  class="btn btn-share btn-next" v-if="$route.query.from=='jinshuian' && $route.name!='UploadTable-oem-v3'" style="display:none" @click="vanPopupShareShow=true"> <i class="iconfont icon-icon_share" style="margin-right:4px;font-size:14px;"></i> 邀请他人填写</button> -->




        <button @click="handleBtnNext" class="btn btn-primary btn-next"
          v-if="$route.query.from == 'jinshuian' || $route.query.from == 'lvat'">立即检测</button>
        <span class="share pc-device-only"
          v-if="($route.query.from == 'jinshuian' || $route.query.from == 'lvat') && $route.name != 'UploadTable-oem-v3'"
          @click="shareCodeDialog"> <i class="iconfont icon-icon_share" style="margin-right:4px;font-size:14px;"></i>
          邀请他人填写</span>
      </div>
    </div>
    <van-popup v-model="vanPopupShareShow" closeable round position="bottom" :style="{ height: 'calc(100vh - 100px)' }">
      <div class="shareDiv">
        <div class="shareTitle">邀请他人填写</div>
        <div class="toptips">
          <i class="iconfont icon-icon_warn"></i>
          <div>因“邀请他人填写”时不能关闭数据采集页面，请“复制链接”后按以下步骤操作！</div>
        </div>
        <div class="shareInput">
          <el-input placeholder="请输入内容" v-model="loginUrl" style="width:345px">
            <el-button slot="append" style="background: #165DFF;color:#fff;border-radius: 0px 8px 8px 0px;"
              @click="loginMsgdial">复制链接</el-button>
          </el-input>
        </div>
        <div class="gekai"></div>
        <div class="sharePng">
          <img src="@/assets/step.png">
        </div>


      </div>

    </van-popup>
    <waitloading ref="waitloadingref" @commitSuccess="commitSuccess"></waitloading>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { Toast } from 'vant';
import {
  breezeOuter,
  breezeOuterSDCheck,
  useAuthorize,
  zjBreezeLoginCreateTask,
  zjBreezeLoginCreateTaskBj,
  newShanxiBreeze,
  zjBreezeLoginV2,
  loginByCodeV2,
  getAuthStatus,
  addExpirationDays,
  getAuthStatus2, commitTesting, v3CodeLogin, v3login
} from "@/api/api";
import waitloading from "./waitloading.vue"

import reportMethod from "./report-method";
// import agreementModal from "./agreementModal.vue";
// import shouquan from "./shouquan.vue";

import "vue-loading-overlay/dist/vue-loading.css";
import { genTestReportOpen, getTaxAreaBureauServiceInfoOem } from "@/api/oem";
import appendQuery from "append-query";
import { Dialog } from "vant";

export default {
  data() {
    return {
      loginUrl: this.$store.state.oem.auth.loginUrl,
      vanPopupShareShow: false,
      thirdDataSource: "", //山东取数的方式
      validateMethod: "", //山东取数的方式
      requiredTables: [],
      isAutoDataNum: 0,
      zjhm: "",
      czydm: "",
      zaNameValue: [],
      nameListArr: null,
      nameInfoList: "",
      identityValue: "",
      jsmobileCode: "",
      showCode: "",

      jscookies: "",
      jsinfo: "",
      dialogVisible: false,
      isloaded: false,
      processTexting: true,
      processTexted: false,
      progressUpload: false,
      progressUploadStatus: "0",
      tables: {},
      tipDialogVisible: false, //刚开始进入的提示框
      fullPage: true,
      loading: false,
      isLoading: false,
      acknowledgeDialogVisible: false,
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      templateDownloadURL: "",
      hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      showSDSDialog: false,
      uploadMethod: sessionStorage.getItem("method") || 1,
      code: 9996,
      index: -1,
      step2Msg: null,
      valueMsg: "",
      area: "",
      bizNo: "",
      radioIndex: "",
      passwordInput: "",
      clickQr: "",
      index1Data: "",
      indexJylsh: "",
      mobile: "",
      mobileCode: "",
      haveUploads: false,
      showTables: false,
      balanceName: "",
      tableData: [],
      taxSiteUsername: this.$store.state.companyInfo.nsrsbh || "",
      batchNo: "",
      isEnableAutoGetData: true, // 汇算清缴是否已经结束
      isTaxAutoProcessing: false, //自动取数进行中
      isVersionList: [9, 11, 15],
      baseVersion: 12, //房地产tag 需要基础信息表
      checkBaseInfoData: "",
      deleteDataType: this.$route.query.deleteDataType,

      // 内蒙
      nmCookies: "",
      nmPhone: "",
      nmMobileCode: "",
      nmIdentityValue: "",
      nminfoData: "",
      nmotherData: "",
      // 新疆
      xjmobileCode: "",
      xjbdgxId: "",
      xjbsrType: "",
      xjcookies: "",
      xjtaxSiteUsername: "",
      xjvalue: "",
      // 重庆
      cqcookies: "",
      cqmobileCode: "",
      cqmobile: "",
      cqloginStatusOne: "",
      // 北京
      bjadditionalPassword: "",
      bjcookies: "",
      bjnameInfoList: "",
      bjvalue: "",
      // 20220810新增
      bjNewPassword: "",
      bjnewIdentCode: "",
      bjNewIndenty: "",
      //
      bjNewName: "",
      newBjToken: "",
      bjNewValue: "",
      newZjBsry: "",
      // 辽宁
      lnmobile: "",
      lnVerifiTypeValue: "",
      lnmobileCode: "",
      lndtlSessionId: "",
      lndzswjTgc: "",
      // 广西
      gcmobileCode: "",
      gcVerifiTypeValue: "",
      gccookies: "",
      gcdjxh: "",
      gcczydm: "",
      gcsfmc: "",
      gcsfzjhm: "",
      // 河南
      hndqmobileCode: "",
      henanInfoListSelectValue: "",
      hndqCookies: "",
      hndqmobileCodeData: {},
      //
      henanInfoListSelectNext: false,
      loginStatusHN: "",
      nsrmcHN: "",
      useridHN: "",
      //重庆
      cqVerifiTypeNext: "",
      cqLoginType: "",
      cqmobileOne: "",
      cqVerifiTypeNextCode: false,
      // 湖南
      hunanCookies: "",
      hunanLoginStatus: "",
      hunanmobileCode: "",
      // 浙江新参数
      zjNewValue: "",
      newZjToken: "",
      newZjBsry: "",
      //
      addreportAndCheck: false,
      // 上海参数
      shpasswordInput: "",
      shBsrs: "",
      shRadio: "",
      shCookies: "",
      shmobileCode: "",
      // 陕西参数
      shanxiCookies: "",
      shanxiLoginData: "",
      shanxidqmobileCode: "",
      shanxiInfoListSelectValue: "",
      // 河北参数
      hebeiNameValue: "",
      hebeidqmobileCode: "",
      hebeiCookies: "",
      hebeiGetInfoBoolean: false,
      // 河北
      hebeiother_data: "",
      hebeiinfo_data: "",
      //
      // 江西参数
      jiangxicookies: "",
      jiangxiData: "",
      jiangxinsrbh: "",
      jiangxinsrmc: "",
      jiangxidqmobileCode: "",
      jiangxiInfoListSelectValue: "",
      // 山西参数
      showShanXiPhoneData: "",
      showShanXiPhoneCode: "",
      sShanxiCookies: "",
      sShanxiloginStatus: "",
      // 福建
      fujianCookies: "",
      fujianPhoneCode: "",
      // 四川
      sichuanInfoData: "",
      sichuanOtherData: "",
      sichuanCookies: "",
      sichuanPhoneCode: "",
      sichuanMobile: "",
      // 天津
      tianJinMobile: "",
      // 黑龙江
      heiLongJOtherData: "",
      heiLongJinfo_data: "",
      heiLongJCookies: "",
      heiLongJPwd: "",
      heiLongJidentityValue: "",
      // 云南
      yunNanCookie: "",
      yunNanJPwd: "",
      yunNanUUID: "",
      yunnanRadio: "",
      yunNanJPhoneCode: "",
      // 吉林
      jiLinJMobile: "",
      jiLinJPhoneCode: "",
      jiLininfo_data: "",
      jiLinDatas: "",
      jiLincookies: "",
      // 青海
      qingHaiJPhoneCode: "",
      qinghaiInfo_data: "",
      qinghaiCookies: "",
      qinghaiOther_data: "",
      qinghaiLogin_status: "",
      qinghaiValObject: "",
      // 浙江新参数
      zjBsryxm: "",
      userName: "",

      // 广东参数
      guangDongMobile: "",
      // 浙江新参数20230508
      zJSelectRoleValue: "",
      // 安徽参数
      anHuiJidentityValue: "",
      anhuiInfoData: "",
      anHuiJCookies: "",
      anHuiJOtherData: "",
      anHuisms_data: "",
      anHuiJPhoneCode: "",
      // 河南
      hnMobile: "",
      // 上海
      shanghaiother_data: "",
      shanghaiinfo_data: "",
      shanghaicookies: "",
      shNameListValue: "",
      // 湖北
      hubeiValue: "",
      //
      //
      //
      tzChecked: false,
      tzValue: "90",
      taskId: '',
    };
  },
  components: {
    reportMethod, waitloading
  },
  props: {
    zq: [Number, String],
    productName: String,
    productID: [Number, String],
    zqLocation: [Number, String],
    bankId: String,
    dataPeriod: [Number, String],
    version: [Number, String],
    isShanDong: [Number, String],
  },
  computed: {
    ...mapState(["authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),
    ...mapState("oem", ["auth"]),
    isMobileDevice() {
      return window.innerWidth < 768;
    },
    token() {
      return "";
    },
    companyId() {
      return "";
    },
    uuid() {
      return this.auth.preInspectId;
    },
    nsrsbm() {
      return this.auth.companyNo;
    },
    nsrsbh() {
      return this.auth.companyTaxNo;
    },
    companyInfo() {
      return {
        nsrsbh: this.nsrsbh,
        companyName: this.auth.companyName,
        nsrsbm: this.auth.companyNo,
      };
    },
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveMsg, false);
  },
  async mounted() {

    window.addEventListener('message', this.receiveMsg, false);

    this.isloaded = false;
    // if (!this.$route.query.uuid) {
    if ((this.$route.query.from != 'jinshuian' && this.$route.query.from != 'lvat') && !this.zq && !this.$route.query.key) {
      this.deviceAdaptiveAlert("征期不能为空");
      return;
    }
    if ((this.$route.query.from == 'jinshuian' || this.$route.query.from == 'lvat') && !this.zq && !this.$route.query.uuid) {
      this.deviceAdaptiveAlert("征期不能为空");
      return;
    }

    //如果税局未对接则手动填写报表
    const res = await getTaxAreaBureauServiceInfoOem({
      taxNo: this.nsrsbh,
      from: this.$route.query.from ? this.$route.query.from : ''
    });

    if (!res.data.available) {
      this.deviceAdaptiveAlert("该地区暂不支持", {
        showClose: false,
        showConfirmButton: false,
      });
      return;
    } else {
      this.uploadMethod = 1;
    }

    //宽谷还是微风
    this.thirdDataSource = res.data.thirdDataSource;
    this.validateMethod = res.data.validateMethod == 2 ? true : false;

    this.isloaded = true;
    // 判断是否取数中  有key的时候判断
    if (this.$route.query.uuid) {
      getAuthStatus(this.nsrsbh, this.uuid).then((res) => {
        if (res.data.authorized != 1) {
          this.isTaxAutoProcessing = true;
          this.$alert("授权已提交", "温馨提示", {
            confirmButtonText: "确定",
            callback: (action) => { },
          });
        }
      });
    }

  },
  methods: {
    commitSuccess() {
      if (this.$route.query.from == 'lvat') {

        let params = {
          programId: this.$store.state.oem.auth.preInspectId,
          type: "1",
          taxNo: this.$store.state.oem.auth.companyTaxNo,
          projectId: this.$route.query.projectId,
          expirationDays: this.tzValue,
          intervalStart: this.$route.query.startYear,
          intervalEnd: this.$route.query.endYear,
          thirdDataSource: this.thirdDataSource
        }
        addExpirationDays(params).then(res => {
          this.loading.close();
          if (res.code != 200) {
            this.$message({
              message: res.message,
              type: "warning",
            });
            return
          } else {
            if (this.isMobileDevice) {
              this.$router.push({
                name: "test-InvoiceUpload-oemV2",
              });
            } else {
              this.$router.push({
                name: "test-InvoiceUpload-oemV2",
                query: {
                  from: 'lvat'
                }
              });
            }
          }
        })
      } else {
        this.$router.push({
          name: "test-InvoiceUpload-oemV2",
        });
      }
    },
    shareCodeDialog() {
      window.parent && window.parent.postMessage("shareCode", "*");
      //如果是土增的链接 轮询访问是否取数完成
      if (this.$route.query.from == "lvat" && this.$route.name != 'UploadTable-oem-v3') {
        // this.$refs.showReportRef.isChecked=true
        this.checkTimer = window.setInterval(() => {
          setTimeout(this.checkAuto(), 0);
          // 每三分钟执行 6000*10 * 3
        }, 30000);
      }
    },
    receiveMsg(e) {
      if (e.data == 'message') {
        this.$refs.showReportRef.isChecked = true
      } else if (e.data == 'commitSuccess') {
        if (this.$route.query.from == 'lvat') {

        let submitFn = breezeOuterSDCheck;
        let param = {
          zq: this.zq || this.$store.state.oem.zq,
          preInspectId: this.uuid,
        };
        submitFn(param, "daqc").then(async (res) => {
          let params = {
            programId: this.$store.state.oem.auth.preInspectId,
            type: "1",
            taxNo: this.$store.state.oem.auth.companyTaxNo,
            projectId: this.$route.query.projectId,
            expirationDays: this.tzValue,
            intervalStart: this.$route.query.startYear,
            intervalEnd: this.$route.query.endYear,
            thirdDataSource: this.thirdDataSource
          }
          if(res.code == 200){

          
          addExpirationDays(params).then(resp => {
            this.loading.close();
            if (resp.code != 200) {
              this.$message({
                message: resp.message,
                type: "warning",
              });
              return
            } else {
              if (this.isMobileDevice) {
                this.$router.push({
                  name: "test-InvoiceUpload-oemV2",
                });
              } else {
                this.$router.push({
                  name: "test-InvoiceUpload-oemV2",
                  query: {
                    from: 'lvat'
                  }
                });
              }
            }
          })
        }
        })
         
        } else {
          this.$router.push({
            name: "test-InvoiceUpload-oemV2",
          });
        }
      }
    },
    showLicen() {
      if (this.$route.name == 'UploadTable-oem-v3') {

        if (window.innerWidth < 750) {
          this.$refs.shouquanRef.openVanPopupShareShow()
          return
        }
        this.$refs.agreementModalRef.openDialog()

      } else {
        if (window.innerWidth < 750) {
          this.$refs.shouquanRef.openVanPopupShareShow()
          return
        }
        window.parent && window.parent.postMessage("showLicen", "*");

      }

    },
    loginMsgdial() {
      var copyTest = 'https:' + this.loginUrl;
      var inputTest = document.createElement("input");
      inputTest.value = copyTest;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand("Copy");
      inputTest.className = "oInput";
      inputTest.style.display = "none";
      Toast('复制成功');
      // this.$message({
      //   message: "复制成功！",
      //   type: "success",
      // });
      //this.loginMsgdialogVisible = false;
    },
    share() {
      this.$refs.showReportRef.loginMsgdialogVisible = true
    },
    checkAuto() {
      getAuthStatus2(this.uuid).then((res) => {
        if (res.code == 200) {
          let authorized = res.data;
          if (authorized) {
            window.clearInterval(this.checkTimer);
            this.$router.push({
              name: "test-InvoiceUpload-oemV2",
              query: {
                from: 'lvat'
              }
            });
            // this.$router.push({
            //  name: "test-InvoiceUpload-oem",
            //  query: {
            //    from: this.$route.query.from
            // }
            //});
          }
        }
      });
    },
    deviceAdaptiveAlert(message, title, options = {}) {
      if (!this.isMobileDevice) {
        this.$alert(message, title, options);
      } else {
        Dialog.alert({
          title: title,
          message: message,
          confirmButtonText: options.confirmButtonText,
        }).then(() => {
          if (options.callback) {
            options.callback();
          }
        });
      }
    },
    handleSkip() {
      // 报税版体验版会有的按钮，可以直接跳手动
      window.parent && window.parent.postMessage("upload-submit-success", "*");
    },
    handleBtnNext() {
      if (!this.$refs.showReportRef.isChecked && this.$route.query.from != 'shiwusuo') {
        if (this.$route.name != 'UploadTable-oem-v3') {
          this.$refs.showReportRef.showLicen2()
          return;
        } else {
          if (window.innerWidth < 750) {
            this.$refs.showReportRef.showLicen2()
            return
          }
          this.$refs.agreementModalRef.openDialog()
          return;

        }
      }

      if (this.$route.query.isDemo && this.$route.query.isDemo !== 'null' && this.$route.query.isDemo !== null) {
        this.loading = this.$loading({
          lock: true,
          text: `${this.$route.query.startYear}年-${this.$route.query.endYear}年数据正在采集中`,
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        setTimeout(() => {
          this.loading.close();
          this.deviceAdaptiveAlert("数据采集成功。", "温馨提示", {
            confirmButtonText: "确定",
            type: "success",
            callback: (action) => {
              this.$router.push({ name: "test-InvoiceUpload-oemV2" });
              window.parent && window.parent.postMessage('demoClick', "*");
            },
          });
        }, 60000)

        return;
      }



      const callbackUrl = this.$route.query.cburl;
      let redirectedURL = callbackUrl
        ? appendQuery(callbackUrl, `programId=${this.uuid}`)
        : "";
      let params = {
        nsrsbm: this.nsrsbm,
      };

      this.useauthorize(async () => {
        //分享链接
        if (this.$route.query.uuid) {
          if ((this.$store.state.oem.auth.channelCode == "lvat" || this.$route.query.from == "lvat" || this.$route.query.from == "jinshuian") && this.index === 999) {
            if (this.validateMethod) {
              const params = {
                taskId: this.taskId,
                smsCode: this.jsmobileCode
              }
              const lvatRes = await v3CodeLogin(params)

              this.$refs.waitloadingref.open({
                statusType: 'loginByCode',
                taskId: params.taskId,
                isGetCode: false
              })
              this.loading.close();

            } else {

              const params = {
                programId: this.uuid,
                userCode: this.zjBsryxm,
                password: this.taxSitePwd,
                userName: this.taxSiteUsername,
                identityType: this.zJSelectRoleValue,
              }
              const lvatRes = await v3login(params)
              this.$refs.waitloadingref.open({
                statusType: 'login',
                taskId: lvatRes.data,
                isGetCode: false
              })
              this.loading.close();
            }
            return;

          }

        }
        //
        localStorage.setItem("taxSiteUsername", this.taxSiteUsername);
        // V2版本 用于事务所\税务师\风控
        if (this.$route.query.version == 2) {
          if ((this.$store.state.oem.auth.channelCode == "jinshuian" || this.$route.query.from == "jinshuian") && this.index == 999) {
            if (this.validateMethod) {
              const params = {
                taskId: this.taskId,
                smsCode: this.jsmobileCode
              }
              const lvatRes = await v3CodeLogin(params)
              window.parent && window.parent.postMessage({
                statusType: 'loginByCode',
                taskId: params.taskId,
                isGetCode: false
              }, "*");
              this.loading.close();

            } else {
              const params = {
                programId: this.uuid,
                userCode: this.zjBsryxm,
                password: this.taxSitePwd,
                userName: this.taxSiteUsername,
                identityType: this.zJSelectRoleValue,
              }
              const lvatRes = await v3login(params)
              window.parent && window.parent.postMessage({
                statusType: 'login',
                taskId: lvatRes.data,
                isGetCode: false
              }, "*");
              this.loading.close();

              // if (lvatRes.code == 200) {
              //   window.parent && window.parent.postMessage("v2-submit-success", "*");

              // } else {
              //   this.$message({
              //     message: lvatRes.message,
              //     type: "warning",
              //   });
              //   return
              // }
            }
            return;
          } else {
            window.parent && window.parent.postMessage("v2-submit-success", "*");
          }

          return;
        }
        // V2版本

        // V3版本 用于小程序
        if (this.$route.query.version == 3) {
          wx.miniProgram.postMessage({ data: "获取成功" });
          wx.miniProgram.navigateBack({ delta: 1, data: 2 });
          return;
        }
        // V3版本
        // 如果是土增 提交保留天数
        if ((this.$store.state.oem.auth.channelCode == "lvat" || this.$route.query.from == "lvat") && this.index === 999) {

          if (this.validateMethod) {
            const params = {
              taskId: this.taskId,
              smsCode: this.jsmobileCode
            }
            const lvatRes = await v3CodeLogin(params)
            window.parent && window.parent.postMessage({
              statusType: 'loginByCode',
              taskId: params.taskId,
              isGetCode: false
            }, "*");
            this.loading.close();

          } else {

            const params = {
              programId: this.uuid,
              userCode: this.zjBsryxm,
              password: this.taxSitePwd,
              userName: this.taxSiteUsername,
              identityType: this.zJSelectRoleValue,
            }
            const lvatRes = await v3login(params)
            this.loading.close();
            window.parent && window.parent.postMessage({
              statusType: 'login',
              taskId: lvatRes.data,
              isGetCode: false
            }, "*");



          }

          return
        }


        // 新的check接口
        let params = {
          zq: this.zq || this.$store.state.oem.zq,
          preInspectId: this.uuid,
        };
        let submitFn = genTestReportOpen;

        if (this.$store.state.oem.auth.channelCode == "lvat" || this.$route.query.from == "lvat") {
          submitFn = breezeOuterSDCheck;
        }
        submitFn(params, "daqc").then(async (res) => {

          if (res.code == 200) {
            // 如果是土增 提交保留天数
            if (this.$store.state.oem.auth.channelCode == "lvat" || this.$route.query.from == "lvat") {
              let params = {
                programId: this.$store.state.oem.auth.preInspectId,
                type: "1",
                taxNo: this.$store.state.oem.auth.companyTaxNo,
                projectId: this.$route.query.projectId,
                expirationDays: this.tzValue,
                intervalStart: this.$route.query.startYear,
                intervalEnd: this.$route.query.endYear,
                thirdDataSource: this.thirdDataSource

              }
              addExpirationDays(params).then(res => {
                this.loading.close();
                if (res.code != 200) {
                  this.$message({
                    message: res.message,
                    type: "warning",
                  });
                  return
                } else {
                  this.$router.push({
                    name: "test-InvoiceUpload-oemV2",
                    query: {
                      from: 'lvat'
                    }
                  });
                }
              })
              return;
            }
            // 前面的判断没进的话 就是第三方的逻辑 带callbackUrl会跳转到自己配置的链接上
            window.parent && window.parent.postMessage("thirdParty-submit-success", "*");

            if (callbackUrl) {
              redirectedURL = appendQuery(redirectedURL, "code=0");
              location.href = redirectedURL;
            } else {
              this.$router.push({
                name: "test-InvoiceUpload-oem",
                query: {
                  from: this.$route.query.from
                }
              });
            }
          } else {
            if (callbackUrl) {
              redirectedURL = appendQuery(redirectedURL, "code=-1");
              location.href = redirectedURL;
            } else {
              //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
              this.deviceAdaptiveAlert(res.msgInfo, "错误", {
                confirmButtonText: "确定",
                type: "error",
                callback: (action) => {
                  this.$router.push({ name: "homePage" });
                },
              });
            }
          }
        });
      });
    },
    // 子组件值变化传递到父组件
    changeName(val) {
      this.taskId = val.taskId;
      this.taxSiteUsername = val.name;
      this.taxSitePwd = val.password;
      this.activeRead = val.active;
      this.index = val.index;
      this.step2Msg = val.step2Msg;
      this.valueMsg = val.value;
      this.area = val.province;
      this.bizNo = val.bizNo;
      this.radioIndex = val.radio;
      this.passwordInput = val.passwordInput;
      this.clickQr = val.clickQr;
      this.indexJylsh = val.indexJylsh;
      this.index1Data = val.index1Data;
      this.mobile = val.mobile;
      this.mobileCode = val.mobileCode;
      this.batchNo = val.batchNo;
      this.jsmobileCode = val.jsmobileCode;
      this.showCode = val.showCode;
      this.jscookies = val.jscookies;
      this.jsinfo = val.jsinfo;
      this.identityValue = val.identityValue;
      this.nameListArr = val.nameListArr;
      this.nameInfoList = val.nameInfoList;
      this.czydm = val.czydm;
      // 内蒙参数
      this.nmCookies = val.nmCookies;
      this.nmPhone = val.nmPhone;
      this.nmMobileCode = val.nmMobileCode;
      this.nmIdentityValue = val.nmIdentityValue;
      this.nminfoData = val.nminfoData;
      this.nmotherData = val.nmotherData;
      // 新疆
      this.xjmobileCode = val.xjmobileCode;
      this.xjbdgxId = val.xjbdgxId;
      this.xjbsrType = val.xjbsrType;
      this.xjcookies = val.xjcookies;
      this.xjtaxSiteUsername = val.xjtaxSiteUsername;
      this.xjvalue = val.xjvalue;
      // 重庆
      this.cqcookies = val.cqcookies;
      this.cqmobileCode = val.cqmobileCode;
      this.cqmobile = val.cqmobile;
      this.cqloginStatusOne = val.cqloginStatusOne;
      // 北京
      this.bjcookies = val.bjcookies;
      this.bjadditionalPassword = val.bjadditionalPassword;
      this.bjnameInfoList = val.bjnameInfoList;
      this.bjvalue = val.bjvalue;
      // 20220810新增
      this.bjNewPassword = val.bjNewPassword;
      this.bjnewIdentCode = val.bjnewIdentCode;
      //
      this.bjNewName = val.bjNewName;
      this.newBjToken = val.newBjToken;
      this.bjNewValue = val.bjNewValue;
      this.bjNewIndenty = val.bjNewIndenty;
      // 辽宁
      this.lnmobile = val.lnmobile;
      this.lnVerifiTypeValue = val.lnVerifiTypeValue;
      this.lnmobileCode = val.lnmobileCode;
      this.lndtlSessionId = val.lndtlSessionId;
      this.lndzswjTgc = val.lndzswjTgc;
      // 广西
      this.gcmobileCode = val.gcmobileCode;
      this.gcVerifiTypeValue = val.gcVerifiTypeValue;
      this.gccookies = val.gccookies;
      this.gcdjxh = val.gcdjxh;
      this.gcczydm = val.gcczydm;
      this.gcsfmc = val.gcsfmc;
      this.gcsfzjhm = val.gcsfzjhm;
      // 河南
      this.hndqmobileCode = val.hndqmobileCode;
      this.henanInfoListSelectValue = val.henanInfoListSelectValue;
      this.hndqCookies = val.hndqCookies;
      this.hndqmobileCodeData = val.hndqmobileCodeData;
      //
      this.henanInfoListSelectNext = val.henanInfoListSelectNext;
      this.loginStatusHN = val.loginStatusHN;
      this.nsrmcHN = val.nsrmcHN;
      this.useridHN = val.useridHN;
      //重庆
      this.cqVerifiTypeNext = val.cqVerifiTypeNext;
      this.cqLoginType = val.cqLoginType;
      this.cqmobileOne = val.cqmobileOne;
      this.cqVerifiTypeNextCode = val.cqVerifiTypeNextCode;
      // 湖南
      this.hunanCookies = val.hunanCookies;
      this.hunanLoginStatus = val.hunanLoginStatus;
      this.hunanmobileCode = val.hunandqmobileCode;
      //
      this.zjNewValue = val.zjNewValue;
      this.newZjToken = val.newZjToken;
      this.newZjBsry = val.newZjBsry;
      // 上海
      this.shpasswordInput = val.shpasswordInput;
      this.shBsrs = val.shBsrs;
      this.shRadio = val.shRadio;
      this.shCookies = val.shCookies;
      this.shmobileCode = val.shmobileCode;
      // 陕西
      this.shanxiCookies = val.shanxiCookies;
      this.shanxiLoginData = val.shanxiLoginData;
      this.shanxidqmobileCode = val.shanxidqmobileCode;
      this.shanxiInfoListSelectValue = val.shanxiInfoListSelectValue;
      // 河北
      this.hebeiNameValue = val.hebeiNameValue;
      this.hebeidqmobileCode = val.hebeidqmobileCode;
      this.hebeiCookies = val.hebeiCookies;
      this.hebeiGetInfoBoolean = val.hebeiGetInfoBoolean;
      //
      this.hebeiother_data = val.hebeiother_data;
      this.hebeiinfo_data = val.hebeiinfo_data;
      // 江西
      this.jiangxicookies = val.jiangxicookies;
      this.jiangxiData = val.jiangxiData;
      this.jiangxinsrbh = val.jiangxinsrbh;
      this.jiangxinsrmc = val.jiangxinsrmc;
      this.jiangxidqmobileCode = val.jiangxidqmobileCode;
      this.jiangxiInfoListSelectValue = val.jiangxiInfoListSelectValue;
      // 山西
      this.showShanXiPhoneData = val.showShanXiPhoneData;
      this.showShanXiPhoneCode = val.showShanXiPhoneCode;
      this.sShanxiCookies = val.sShanxiCookies;
      this.sShanxiloginStatus = val.sShanxiloginStatus;
      // 福建
      this.fujianCookies = val.fujianCookies;
      this.fujianPhoneCode = val.fujianPhoneCode;
      // 四川
      this.sichuanInfoData = val.sichuanInfoData;
      this.sichuanOtherData = val.sichuanOtherData;
      this.sichuanCookies = val.sichuanCookies;
      this.sichuanPhoneCode = val.sichuanPhoneCode;
      this.sichuanMobile = val.sichuanMobile;
      // 天津
      this.tianJinMobile = val.tianJinMobile;
      // 黑龙江
      this.heiLongJOtherData = val.heiLongJOtherData;
      this.heiLongJinfo_data = val.heiLongJinfo_data;
      this.heiLongJCookies = val.heiLongJCookies;
      this.heiLongJPwd = val.heiLongJPwd;
      this.heiLongJidentityValue = val.heiLongJidentityValue;
      // 云南
      this.yunNanCookie = val.yunNanCookie;
      this.yunNanJPwd = val.yunNanJPwd;
      this.yunNanUUID = val.yunNanUUID;
      this.yunnanRadio = val.yunnanRadio;
      this.yunNanJPhoneCode = val.yunNanJPhoneCode;
      // 吉林
      this.jiLinJMobile = val.jiLinJMobile;
      this.jiLinJPhoneCode = val.jiLinJPhoneCode;
      this.jiLininfo_data = val.jiLininfo_data;
      this.jiLinDatas = val.jiLinDatas;

      this.jiLincookies = val.jiLincookies;
      // 青海
      this.qingHaiJPhoneCode = val.qingHaiJPhoneCode;
      this.qinghaiInfo_data = val.qinghaiInfo_data;
      this.qinghaiCookies = val.qinghaiCookies;
      this.qinghaiOther_data = val.qinghaiOther_data;
      this.qinghaiLogin_status = val.qinghaiLogin_status;
      this.qinghaiValObject = val.qinghaiValObject;
      // 浙江新参数
      this.zjBsryxm = val.zjBsryxm;
      // 广东参数
      this.guangDongMobile = val.guangDongMobile;
      // 浙江
      this.zJSelectRoleValue = val.zJSelectRoleValue;
      // 安徽
      this.anHuiJidentityValue = val.anHuiJidentityValue;
      this.anhuiInfoData = val.anhuiInfoData;
      this.anHuiJCookies = val.anHuiJCookies;
      this.anHuiJOtherData = val.anHuiJOtherData;
      this.anHuisms_data = val.anHuisms_data;
      this.anHuiJPhoneCode = val.anHuiJPhoneCode;
      // 河南
      this.hnMobile = val.hnMobile;
      // 上海概半
      this.shanghaiother_data = val.shanghaiother_data;
      this.shanghaiinfo_data = val.shanghaiinfo_data;
      this.shanghaicookies = val.shanghaicookies;
      this.shNameListValue = val.shNameListValue;
      // 湖北
      this.hubeiValue = val.hubeiValue;
      //
      //
      //
      this.tzChecked = val.tzChecked;
      this.tzValue = val.tzValue;
    },
    // 授权接口，授权之前的校验
    useauthorize(callback) {

      var uPattern = /^[a-zA-Z0-9]{15,20}$/;
      if (!uPattern.test(this.taxSiteUsername)) {
        this.$message.warning("请输入账号密码");
        return;
      }
      if (this.clickQr) {
        this.deviceAdaptiveAlert("请点击验证按钮！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (!this.mobileCode && this.index == 1) {
        this.deviceAdaptiveAlert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.identityValue && this.index == 2) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.jsmobileCode && this.index == 2) {
        this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (!this.jsmobileCode && this.index == 999 && this.showCode) {
        this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (!this.zjBsryxm && this.index == 999 && !this.showCode) {
        this.deviceAdaptiveAlert("请输入身份信息！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.index == 3 && !this.czydm) {
        this.deviceAdaptiveAlert(
          "请输入委托人手机号/身份证号码/操作员代码！",
          "温馨提示",
          {
            confirmButtonText: "确定",
          }
        );
        return;
      }

      if (this.index == 4 && !this.nmPhone) {
        this.deviceAdaptiveAlert("身份证/用户名/手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 4 && !this.nmMobileCode) {
        this.deviceAdaptiveAlert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 5 && !this.xjvalue) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 5 && !this.xjmobileCode) {
        this.deviceAdaptiveAlert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 7 && !this.hebeidqmobileCode) {
        this.deviceAdaptiveAlert("验证码不能为空", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjvalue) {
        this.deviceAdaptiveAlert("请选择办税人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjNewPassword) {
        this.deviceAdaptiveAlert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjnewIdentCode) {
        this.deviceAdaptiveAlert("身份证号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjadditionalPassword) {
        this.deviceAdaptiveAlert("密码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 10 && !this.gcVerifiTypeValue) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 10 && !this.gcmobileCode) {
        this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 11 && !this.hndqmobileCode) {
        this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        this.index == 12 &&
        this.hunanLoginStatus == "sfChoose" &&
        !this.hunanmobileCode
      ) {
        this.deviceAdaptiveAlert("验证码不能为空", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        this.index == 14 &&
        !this.shanxiInfoListSelectValue &&
        this.thirdDataSource == "DB_WF"
      ) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 15 && !this.hebeidqmobileCode) {
        this.deviceAdaptiveAlert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 16 && !this.jiangxiInfoListSelectValue) {
        this.deviceAdaptiveAlert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 16 && !this.jiangxidqmobileCode) {
        this.deviceAdaptiveAlert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.activeRead) {
        this.deviceAdaptiveAlert("请阅读并勾选授权协议", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.clickQr) {
        this.deviceAdaptiveAlert("请先点击验证按钮", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      // 土增还需要判断是否阅读授权协议
      // if (this.$route.query.from == "lvat" && !this.tzChecked) {
      //   this.deviceAdaptiveAlert("请阅读并同意授权协议", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }
      //
      let text
      // if (this.$route.query.from == 'lvat') {
      //   text=`${this.$route.query.startYear}年-${this.$route.query.endYear}年数据正在采集中`;
      // }else{
      text = '正在提交数据,请耐心等待！'
      // }
      this.loading = this.$loading({
        lock: true,
        text: text,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      console.log(this.index)
      if (this.index == 999) {

        callback();
      }
      // 通用授权
      if (this.index == -1) {
        let params = {
          id: this.companyId,
          authorizeResult: true,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          taxpayerId: this.taxSiteUsername,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          batchNo: this.batchNo,
        };

        useAuthorize(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 浙江地区授权
      if (this.index == 0) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            id: this.companyId,
            nsrsbm: this.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: this.token,
            zq: this.zq || this.$store.state.oem.zq,
            taxpayerId: this.taxSiteUsername,
            taxSiteUsername: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            acwTc: this.step2Msg.acw_tc,
            aliyungfTc: this.step2Msg.aliyungf_tc,
            dtlSessionId: this.step2Msg.dtl_SESSION_ID,
            loginType: this.radioIndex == 1 ? "SMRZ" : "PWD",
            additionalUsername: this.valueMsg,
            additionalPassword: this.radioIndex == 1 ? this.bizNo : this.passwordInput,
            area: this.area,
            batchNo: this.batchNo,
            cookies: this.step2Msg,
            djxh: this.nameInfoList.djxh,
            nsrmc: this.nameInfoList.nsrmc,
            sfzjhm: this.nameInfoList.sfzjhm,
            sjhm: this.nameInfoList.sjhm,
            swjgDm: this.nameInfoList.swjgDm,
            type: this.nameInfoList.type,
            zjUuid: this.nameInfoList.uuid,
            xm: this.nameInfoList.xm,
          };
          breezeOuter(params, "daqc").then((res) => {
            this.loading.close();

            if (res.msgCode == 1 || res.code == '200') {

              callback();
            } else {

              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            // czydm: this.zjNewValue,
            bsryxm: this.zjBsryxm, //手机号或者身份证号
            companyNo: this.nsrsbm,
            password: this.taxSitePwd.trim(),
            programId: this.uuid,
            userId: this.taxSiteUsername.trim(),
            zq: this.zq || this.$store.state.oem.zq,
            dlsf: this.zJSelectRoleValue,
            // bsry: this.newZjBsry,
          };
          zjBreezeLoginV2(params, "daqc").then((res) => {
            this.loading.close();
            if (res.msgCode == 1 || res.code == '200') {
              callback();
            } else {

              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 宁波授权
      if (this.index == 1) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername.trim(),
          taxSiteUsername: this.mobile.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          batchNo: this.batchNo,
          area: this.area,
          otherData: this.indexJylsh,
          cookies: this.index1Data,
          smsCode: this.mobileCode.trim(),
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 江苏授权
      if (this.index == 2) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          taxpayerId: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          smsCode: this.jsmobileCode,
          batchNo: this.batchNo,
          area: this.area,
          jsLoginType: "1",
          rybz: this.jsinfo.rybz,
          yxbz: this.jsinfo.yxbz,
          bz: this.jsinfo.bz,
          xm: this.jsinfo.xm,
          tel: this.jsinfo.tel,
          sjhm: this.jsinfo.sjhm,
          sfzh: this.jsinfo.sfzh,
          zjlx: this.jsinfo.zjlx,
          type: this.jsinfo.type,
          cookies: this.jscookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 山东授权
      if (this.index == 3) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            id: this.companyId,
            nsrsbm: this.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: this.token,
            zq: this.zq || this.$store.state.oem.zq,

            taxpayerId: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            batchNo: this.batchNo,
            area: this.area,
            czydm: this.czydm,
            taxSiteUsername: this.czydm,
            thirdDataSource: this.thirdDataSource,
          };
          breezeOuter(params, "daqc").then((res) => {
            this.loading.close();
            if (res.msgCode == 1 || res.code == '200') {
              callback();
            } else {

              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            id: this.companyId,
            nsrsbm: this.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: this.token,
            zq: this.zq || this.$store.state.oem.zq,

            bsryxm: this.czydm, //手机号或者身份证号
            companyNo: this.nsrsbm,
            password: this.taxSitePwd.trim(),
            programId: this.uuid,
            userId: this.taxSiteUsername.trim(),
            zq: this.zq || this.$store.state.oem.zq,
            loginVersion: "old",
          };
          zjBreezeLoginV2(params, "daqc").then((res) => {
            this.loading.close();
            if (res.msgCode == 1 || res.code == '200') {
              callback();
            } else {

              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 内蒙授权
      if (this.index == 4) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            id: this.companyId,
            nsrsbm: this.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: this.token,
            zq: this.zq || this.$store.state.oem.zq,
            taxpayerId: this.taxSiteUsername.trim(),
            taxSitePwd: this.taxSitePwd.trim(),
            area: this.area,
            batchNo: this.batchNo,
            cookies: this.nmCookies,
            // legalMobile: this.nmPhone,
            smsCode: this.nmMobileCode,
            taxSiteUsername: this.nmPhone,
            infoData: this.nminfoData,
            otherData: this.nmotherData,
          };
          breezeOuter(params, "daqc").then((res) => {
            this.loading.close();
            if (res.msgCode == 1 || res.code == '200') {
              callback();
            } else {

              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            programId: this.uuid,
            yzm: this.nmMobileCode,
            zq: this.zq || this.$store.state.oem.zq,
            datas: this.jiLinDatas ? this.jiLinDatas : ''
          };
          loginByCodeV2(params).then((res) => {
            this.loading.close();
            if (res.msgCode == 1 || res.code == '200') {
              callback();
            } else {

              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }
      // 新疆授权
      if (this.index == 5) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,

          taxpayerId: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.xjmobileCode,
          bdgxId: this.xjbdgxId,
          bsrType: this.xjbsrType,
          cookies: this.xjcookies,
          taxSiteUsername: this.xjtaxSiteUsername,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 重庆授权
      if (this.index == 7) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.hebeiNameValue,
          taxSitePwd: this.taxSitePwd,
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.hebeidqmobileCode,
          cookies: this.hebeiCookies,
          otherData: this.hebeiother_data,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 北京授权
      if (this.index == 8) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            id: this.companyId,
            nsrsbm: this.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: this.token,
            zq: this.zq || this.$store.state.oem.zq,
            //
            taxpayerId: this.taxSiteUsername,
            taxSiteUsername: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            loginType: 1,
            additionalUsername: this.taxSiteUsername,
            additionalPassword: this.bjadditionalPassword,
            area: this.area,
            batchNo: this.batchNo,
            cookies: this.bjcookies,
            //
            xm: this.bjnameInfoList.xm,
            sjhm: this.bjnameInfoList.sjhm,
            sfzjhm: this.bjnameInfoList.zjhm,
            sfmc: this.bjnameInfoList.sfmc,
            key: this.bjnameInfoList.key,
            // 20220810新增
            confirmCellphone: this.bjNewPassword,
            confirmIdNumber: this.bjnewIdentCode,
          };
          breezeOuter(params, "daqc").then((res) => {
            this.loading.close();
            if (res.msgCode == 1 || res.code == '200') {
              callback();
            } else {

              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            bsryxm: this.bjNewName,
            czydm: this.bjNewValue,
            dlsf: this.bjNewIndenty,
            sfzjhm: this.bjnewIdentCode,
            sjhm: this.bjNewPassword,
            password: this.bjadditionalPassword,
            programId: this.uuid,
            token: this.newBjToken,
            zq: this.zq || this.$store.state.oem.zq,
          };
          zjBreezeLoginCreateTaskBj(params, "daqc").then((res) => {
            this.loading.close();
            if (res.msgCode == 1 || res.code == '200') {
              callback();
            } else {

              this.$alert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 辽宁授权
      if (this.index == 9) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.hebeiNameValue,
          taxSitePwd: this.taxSitePwd,
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.hebeidqmobileCode,
          cookies: this.hebeiCookies,
          otherData: this.hebeiother_data,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 广西授权
      if (this.index == 10) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          smsCode: this.gcmobileCode,
          cookies: this.gccookies,
          djxh: this.gcdjxh,
          czydm: this.gcczydm,
          sfmc: this.gcsfmc,
          sfzjhm: this.gcsfzjhm,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 河南授权
      if (this.index == 11) {
        this.hndqmobileCodeData.sms_code = this.hndqmobileCode;
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          additionalUsername: this.taxSiteUsername,
          additionalPassword: this.taxSitePwd,
          cookies: this.hndqCookies,
          otherData: this.hndqmobileCodeData,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 湖南授权
      if (this.index == 12) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          smsCode: this.hunanmobileCode,
          cookies: this.hunanCookies,
          loginStatus: this.hunanLoginStatus,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 上海授权
      if (this.index == 13) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          // 验证码
          smsCode: this.shmobileCode,
          //
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          additionalUsername: this.shNameListValue,
          additionalPassword: this.taxSitePwd, // 二级密码
          otherData: this.shanghaiother_data,
          cookies: this.shanghaicookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 陕西授权
      if (this.index == 14) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            id: this.companyId,
            nsrsbm: this.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: this.token,
            zq: this.zq || this.$store.state.oem.zq,
            //
            taxpayerId: this.taxSiteUsername,
            taxSiteUsername: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            area: this.area,
            cookies: this.shanxiCookies,
            data: this.shanxiLoginData ? JSON.stringify(this.shanxiLoginData) : "",
            smsCode: this.shanxidqmobileCode,
            batchNo: this.batchNo,
          };
          breezeOuter(params, "daqc").then((res) => {
            this.loading.close();
            if (res.msgCode == 1 || res.code == '200') {
              callback();
            } else {

              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            yzm: this.shanxidqmobileCode,
            programId: this.uuid,
            zq: this.zq || this.$store.state.oem.zq,
          };
          newShanxiBreeze(params, "daqc").then((res) => {
            this.loading.close();
            if (res.msgCode == 1 || res.code == '200') {
              callback();
            } else {

              this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 河北授权
      if (this.index == 15) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.hebeiNameValue,
          taxSitePwd: this.taxSitePwd,
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.hebeidqmobileCode,
          cookies: this.hebeiCookies,
          otherData: this.hebeiother_data,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 江西授权
      if (this.index == 16) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          cookies: this.jiangxicookies,
          data: JSON.stringify(this.jiangxiData),
          swjgDm: this.jiangxinsrbh,
          nsrmc: this.jiangxinsrmc,
          smsCode: this.jiangxidqmobileCode,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 山西授权
      if (this.index == 17) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.hebeiNameValue,
          taxSitePwd: this.taxSitePwd,
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.hebeidqmobileCode,
          cookies: this.hebeiCookies,
          otherData: this.hebeiother_data,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 福建授权
      if (this.index == 18) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          legalMobile: "", //手机号
          smsCode: this.fujianPhoneCode,
          dzswjTgc: this.fujianCookies.dzswj_TGC,
          tgc: this.fujianCookies.tgc,
          jsessionid: this.fujianCookies.jsessionid,
          route: this.fujianCookies.route,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 四川授权
      if (this.index == 19) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          smsCode: this.sichuanPhoneCode,
          infoData: this.sichuanInfoData,
          otherData: this.sichuanOtherData,
          cookies: this.sichuanCookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 天津授权
      if (this.index == 20) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          legalMobile: this.tianJinMobile,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      //
      // 黑龙江
      if (this.index == 21) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.heiLongJPwd,
          //
          otherData: this.heiLongJOtherData,
          infoData: this.heiLongJidentityValue,
          cookies: this.heiLongJCookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 云南
      if (this.index == 22) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.yunnanRadio == "PWD" ? this.yunNanJPwd : this.taxSitePwd,
          //
          dtlSessionId: this.yunNanCookie.dtl_SESSION_ID,
          acwTc: this.yunNanCookie.acw_tc,
          zjUuid: this.yunNanUUID,
          loginType: this.yunnanRadio,
          smsCode: this.yunNanJPhoneCode,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 吉林
      if (this.index == 23) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.taxSitePwd,
          //
          phone: this.jiLinJMobile,
          smsCode: this.jiLinJPhoneCode,
          infoData: this.jiLininfo_data,
          cookies: this.jiLincookies,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 青海
      if (this.index == 24) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.taxSitePwd,
          //
          otherData: this.qinghaiOther_data,
          smsCode: this.qingHaiJPhoneCode,
          infoData: this.qinghaiValObject,
          cookies: this.qinghaiCookies,
          loginStatus: this.qinghaiLogin_status,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 广东
      if (this.index == 25) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.taxSitePwd,
          //
          taxSiteUsername: this.guangDongMobile,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 安徽
      if (this.index == 26) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.taxSitePwd,
          //
          infoData: this.anhuiInfoData,
          cookies: this.anHuiJCookies,
          otherData: this.anHuiJOtherData,
          // data: JSON.stringify(this.anHuisms_data),
          tgc: this.anHuisms_data.qr_token,
          zjUuid: this.anHuisms_data.qr_uuid,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
      // 湖北
      // 安徽
      if (this.index == 27) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.hubeiValue,
          area: this.area,
          batchNo: this.batchNo,
          taxSitePwd: this.taxSitePwd,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 深圳授权
      if (this.index == 28) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.hebeiNameValue,
          taxSitePwd: this.taxSitePwd,
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.hebeidqmobileCode,
          cookies: this.hebeiCookies,
          otherData: this.hebeiother_data,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 宁夏授权
      if (this.index == 29) {
        let params = {
          id: this.companyId,
          nsrsbm: this.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: this.token,
          zq: this.zq || this.$store.state.oem.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.hebeiNameValue,
          taxSitePwd: this.taxSitePwd,
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.hebeidqmobileCode,
          cookies: this.hebeiCookies,
          otherData: this.hebeiother_data,
        };
        breezeOuter(params, "daqc").then((res) => {
          this.loading.close();
          if (res.msgCode == 1 || res.code == '200') {
            callback();
          } else {

            this.deviceAdaptiveAlert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
    },
  },
  watch: {},
  destroyed() {
    window.clearInterval(this.checkTimer);
  },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>
<style lang="stylus" scoped>
.pc-device-only.share{
  cursor :pointer;

  display:inline-block!important;
  color:#165DFF;
  font-size:14px;
  margin-left:154px;
}
.tz-check {
  margin: 12px 0 24px 189px;
  color: #0f3bec;
  font-size: 14px;

  >>> .el-checkbox__input.is-checked + .el-checkbox__label {
    color: rgba(0,0,0,0.6);
  }

  .check {
    cursor: pointer;
    font-size: 14px;
  }
}

>>>.van-overlay {
  z-index: 1000 !important;
}
.shareDiv{
  .shareTitle{
    box-sizing:border-box;
    padding:14px;
    height: 54px;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #1D2129;
  }
  .shareInput{
    box-sizing:border-box;
    
    padding:20px;
    //display:flex;

  }
  .sharePng{
    padding:15px;
    box-sizing:border-box;
    overflow:auto;
    height:50vh;
    >img{
      width:100%;
    }
  }
.gekai{
  width: 100%;
  height: 10px;
  background: #F4F6F8;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
>>>.el-input-group__append{
  border-radius:8px;
}
}
.pc-device-only {
  display :block!important;
}


@media screen and (max-width: 768px) {
  .tz-check{
    margin-left:12px;
  }
  .pc-device-only.share {
    display :none!important;
  }
  .toptips{
    display:flex!important;
    box-sizing:border-box;
    padding:10px 15px;
    background: #FFF7E8;
    >i{
      color:rgba(253, 179, 32, 1);
      margin-right:8px;
      font-size:16px;
    }
    >div{
      
    color: #FDB320;
          line-height: 18px;
          
    font-size: 12px;
    }
  }
  .btn{
    width: 3.3rem!important;
    height: 44px;
    text-align:center!important;
  }
  .btn.btn-primary.btn-next{
    margin-left:15px!important;
  }
  .btn-share{
    display:inline-block!important;
  }
}
.tip {
  margin 0 48px !important
  width auto !important
}

.data-loading-wrapper {
  height: 722px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text {
    margin-top: 47px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
  }
}

.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.tipDialog {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .img {
      width: 174px;
      flex-shrink: 0;
    }

    .right-wrapper {
      margin-left: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #062340;
      }

      .desc {
        margin-top: 16px;
        color: #6b7897;
        font-size: 16px;
        line-height: 1.5;
      }

      .btn-i-get {
        height: 40px;
        line-height: 40px;
        margin-top: 17px;
      }
    }
  }
}

.the-title {
  margin: 30px 30px 30px 0;
}

.content-body {
  margin: 20px;
  background: #fff;
  border-radius: 8px;
  padding: 0;
}

.upload-footer {
  margin-top: 16px;

  .tip {
    float: left;
    color: #FAAD14;
    font-size: 14px;
  }

  .acknowlege, .btn-download {
    background: #fff;
    font-size: 14px;
    float: right;
    color: #2F54EB;
    vertical-align: middle;

    .icon {
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .acknowlege {
    margin-right: 48px;
    cursor: pointer;
    float: right;
  }

  .acknowlege:hover, .btn-download:hover {
  }
}

.table-list {
  margin-top: 24px;
}

.footer {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}

// >>> .el-dialog__header {
// padding: 0;
// }

// >>> .section-title {
// margin-top: 10px;
// }
.section-box {
  padding 0

  .title {
    color: rgba(0, 0, 0, 0.8);
    padding 16px 24px
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #eeeeee;
    text-align left

    .tips {
      font-size: 14px;
      color: #f5222d;
      margin-left: 16px;
    }
  }
}

.page-wrapper {
  // padding-bottom: 84px;
  //overflow:auto;
  //height:calc( 100% - 2.5rem);
  //padding-bottom:8px;
}

.progressdis {
  width: 90%;
  margin: 2px auto 16px;
  margin-bottom: 0;
}

.btn-right-none {
  margin-right: 6px !important;
}

.report-method {
  margin-top: 16px;
}

.nomargin {
  margin: 0 30px;
  padding: 0;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.mainHeader {
  display: flex;
  justify-content: space-between;
}

.text-status {
  width: 10%;
}

.tab-wrapper {
  border-bottom 1px solid #d9d9d9;
}

.uploadMethodText {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 24px;
  align-items center
  justify-content center
}

.uploadMethodItem {
  padding 0px 50px
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: #fff;
  border-radius: 18px 18px 0 0;
  display flex
  cursor: pointer;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.zqLocation {
  color: #2F54EB;
  font-weight 600
  border: 1px solid #D9D9D9;
  border-bottom 0 none
}

.fix-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.fix-flexs {
  display: flex;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
}

.fix-flexs1 {
  margin-left: 24px;
}

.byHand {
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 24px;
}

.drog_texts {
  padding-top: 10px;
  padding-bottom: 14px;
  width: 304px;
}

.upload-text {
  display: flex;
  width: 100%;
  overflow: hidden;
  box-sizing border-box
}

.table-lists {
  flex: 1;
  margin-top: 0;
  padding-top: 0px;
}


// 余额表
.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -300px;
  width: 1158px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.fix-flexsL {
  width: 25%;
  padding-right: 32px;
}

.fix-flexsR {
  width: 75%;
}

.fix-flexsLr {
  width: 100%;
  // max-width: auto;
}

.flex-max-widh {
  max-width: 304px;
}

.totip {
  position: absolute;
  left: 390px;
  top: 8px;
}

.noPadding {
  padding-right: 0;
}

.progressUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2022;
}

.progressBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 638px;
  height: 406px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.processText {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2F54EB;
  line-height: 28px;
  margin-top: 25px;
}

.processTexted {
  color: #52C41A;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 28px;
  margin-top: 25px;
}

>>> .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  top: 80%;
}

.undraw_upload {
  position: absolute;
  width: 180px;
  height: 132px;
  top: 90px;
}

.processTextedNo {
  visibility: hidden;
}

.baseVersion {
  height: 36px;
  line-height: 36px;
  padding: 0 6px;
  background: #2F54EB;
  margin: 4px 6px 0 0;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

>>> .footer-wrapper .inner {
  margin-left 0
}

.footer2 {
  margin-left: 180px;
  margin-top: 24px;
  .btn {
    width: 100px;
  }
}

@media screen and (max-width: 768px) {
  .section-box {
    //background red
    margin: 0 15px
    box-shadow none

    .container {

    }


  }

  .btn-next {
    margin 0 !important
  }
  .dali-style-box{
    position:relative;
    height:calc(100vh);
    //padding-bottom:55px;
    //overflow:auto;
  }
  .footer2 {
    margin: 0 auto;
    margin-top: 12px ;
    text-align: center;
    //position:absolute;
    //border-top:1px solid rgba(14, 20, 41, 0.06);
    padding-top:10px;
    bottom:0;
    width:100%;
    background:#fff
  }
}
</style>
